export default {
  data: () => ({}),

  computed: {},

  methods: {
    setSound(soundCloudId, Point_Name) {
      this.$store.dispatch("mediaSetTrackAndPlay", {
        soundCloudId,
        Point_Name
      });
    },
    getAudioDetails(name, id) {
      let isPlayingThisTrack = false;
      let isLoadedThisTrack = false;
      if (
        this.isPlaying === true &&
        this.playingSiteName &&
        this.playingSiteName === name &&
        this.currentTrack === id
      ) {
        isPlayingThisTrack = true;
      }

      if (
        this.playingSiteName &&
        this.playingSiteName === name &&
        this.currentTrack === id
      ) {
        isLoadedThisTrack = true;
      }

      return {
        playingSiteName: this.playingSiteName,
        isPlaying: this.isPlaying,
        isPlayingThisTrack,
        isLoadedThisTrack
      };
    },

    openYoutube(youTubeAddress, Point_Name) {
      if (
        youTubeAddress.toLowerCase().includes("youtube") ||
        youTubeAddress.toLowerCase().includes("https://youtu")
      ) {
        this.$store.dispatch("mediaOpenYouTubeModal", {
          youTubeAddress,
          Point_Name
        });
      } else {
        window.open(youTubeAddress, "_blank");
      }
    }
  },
  created() {},
  destroyed() {},
  mounted() {}
};
