<template>
  <div class="text-center">
    <v-dialog v-model="auth0dialog" width="750">
      <template v-slot:activator="{ on, attrs }">
        <v-icon class="mr-2" v-on="on" v-bind="attrs">
          mdi-card-account-details
        </v-icon>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Item
        </v-card-title>

        <div class="content">
          <v-divider></v-divider>

          <template>
            <v-card-title class="text-h5 blue lighten-2">
              Point_Name: {{ data.Point_Name }}
            </v-card-title>
            <br />
          </template>

          <v-divider></v-divider>

          <template>
            <v-card-title class="text-h5 blue lighten-2">
              Site_Marker_Number: {{ data.Site_Marker_Number }}
            </v-card-title>
            <br />
          </template>

          <v-divider></v-divider>

          <template>
            <v-card-title class="text-h5 blue lighten-2">
              Content
            </v-card-title>
            <hr />
            <div
              v-html="
                data.content && data.content.rendered
                  ? data.content.rendered
                  : null
              "
            ></div>
            <hr />
          </template>

          <v-divider></v-divider>

          <v-card-title class="text-h5 blue lighten-2">
            Properties
          </v-card-title>
          <vue-json-pretty :data="cutDown"> </vue-json-pretty>
          <v-divider></v-divider>

          <template v-if="data.structuredData">
            <v-card-title class="text-h5 blue lighten-2">
              StructuredData
            </v-card-title>
            <vue-json-pretty :data="data.structuredData"> </vue-json-pretty>
          </template>

          <v-divider></v-divider>

          <template v-if="data.icon_obj">
            <v-card-title class="text-h5 blue lighten-2">
              icon_obj
            </v-card-title>
            <vue-json-pretty :data="data.icon_obj"> </vue-json-pretty>
          </template>

          <v-divider></v-divider>

          <template>
            <v-card-title class="text-h5 blue lighten-2">
              media
            </v-card-title>
            <vue-json-pretty :data="data.media"> </vue-json-pretty>
          </template>

          <v-divider></v-divider>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="auth0dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "itemDialog",
  computed: {
    cutDown() {
      let clone = { ...this.data };

      delete clone.structuredData;
      delete clone.icon_obj;
      delete clone.content;
      delete clone.media;

      return clone;
    }
  },
  props: {
    data: Object
  },
  data() {
    return {
      auth0dialog: false
    };
  },
  async mounted() {},
  methods: {
    loadAuth0Dialog() {
      this.auth0dialog = true;
    }
  },

  watch: {
    // whenever question changes, this function will run
    auth0dialog: function(/*newValue*/) {
      //do nothing;
    }
  }
};
</script>
<style scoped>
.content {
  overflow: auto;
  width: 100%;
  height: calc(100vh - 160px);
  max-height: calc(100vh - 160px);
}
</style>
