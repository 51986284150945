// import Vue from 'vue'
const state = {
  initTrack: "637030785",
  currentTrack: "637030785",
  playingSiteName: "",
  isPlaying: false,
  command: false,
  youTubeTitle: null,
  youTubeAddress: null,
  youTubeOpen: null
};
const mutations = {
  SET_TRACK(state, newValue) {
    state.currentTrack = newValue;
  },
  UPDATE_IS_PLAYING(state, newValue) {
    state.isPlaying = newValue;
  },
  UPDATE_COMMAND(state, newValue) {
    state.command = newValue;
  },
  SET_TRACK_NAME(state, newValue) {
    state.playingSiteName = newValue;
  },
  SET_YOU_TUBE_ADDRESS(state, newValue) {
    state.youTubeAddress = newValue;
  },
  SET_YOU_TUBE_TITLE(state, newValue) {
    state.youTubeTitle = newValue;
  },
  OPEN_YOU_TUBE(state, newValue) {
    state.youTubeOpen = newValue;
  }
};
const actions = {
  mediaOpenYouTubeModal({ commit }, value) {
    let { youTubeAddress, Point_Name } = value;

    //let { address } = settings;
    commit("UPDATE_COMMAND", "pause");

    commit("SET_YOU_TUBE_TITLE", Point_Name);
    commit("SET_YOU_TUBE_ADDRESS", youTubeAddress);
    commit("OPEN_YOU_TUBE", true);
  },

  mediaClearYouTubeTracker({ commit }, value) {
    if (value) {
      //do nothing;
    }
    commit("OPEN_YOU_TUBE", false);
  },

  mediaClearYouTubeAddress({ commit }, value) {
    if (value) {
      //do nothing;
    }
    commit("SET_YOU_TUBE_ADDRESS", false);
    commit("OPEN_YOU_TUBE", false);
  },

  mediaUpdatePlayingName({ commit }, state) {
    commit("SET_TRACK_NAME", state);
  },
  mediaUpdateIsPlaying({ commit }, state) {
    commit("UPDATE_IS_PLAYING", state);
  },
  mediaUpdateCommand({ commit }, command) {
    commit("UPDATE_COMMAND", command);
  },
  mediaSetTrackAndPlay({ commit }, value) {
    commit("SET_YOU_TUBE_ADDRESS", false);
    commit("OPEN_YOU_TUBE", false);

    let { soundCloudId, Point_Name } = value;
    if (
      state.currentTrack === soundCloudId &&
      state.playingSiteName === Point_Name
    ) {
      //is current playing this, so just toggle
      commit("UPDATE_COMMAND", "toggle");
    } else {
      commit("SET_TRACK", soundCloudId);
      commit("SET_TRACK_NAME", Point_Name);
    }
  }
};
const getters = {};

export default {
  state,
  getters,
  actions,
  mutations
};
