<template>
  <maptivateInfoButton
    :Site_Marker_Number="Site_Marker_Number"
    :isPopup="isPopup"
    butType="flyTo"
    :width="width"
    :color="appSettings.mediaButtonColor"
    icon="mdi-map-marker"
    :title="`jump to: ${flyTo_Point_Name}`"
    :clickfunction="clickEvent"
  />
</template>

<script>
import { eventBus } from "../../main";

import maptivateInfoButton from "../ui/maptivateInfoButton";

export default {
  name: "FlyToPointButton",
  components: {
    maptivateInfoButton
  },
  data: () => ({
    ready: false,
    audioPlayIcon: "false"
  }),
  props: {
    //Point_Name: String,
    //Site_Marker_Number: String,

    pointSet: Object,
    sectionOrder: Number,
    sectionCount: Number,
    Site_Marker_Number: String,

    targetPointId: Number, //post id no marker name;

    width: String,
    appSettings: Object,

    isPopup: Boolean
  },
  computed: {
    flyToSearch() {
      let found = false;
      const matchProp = "post_id";
      const layers = ["markerList", "geoJson_artwork", "geoJson_signage"];
      layers.forEach(layer => {
        if (
          this.targetPointId &&
          !found &&
          this?.pointSet[layer] &&
          this?.pointSet[layer]?.data?.features &&
          this?.pointSet[layer]?.data?.features[0]
        ) {
          try {
            this.pointSet[layer].data.features.forEach(site => {
              if (
                site?.properties[matchProp] &&
                site.properties[matchProp] == this.targetPointId
              ) {
                found = { site, layer };
              }
            });

            //post_id
          } catch (error) {
            //do nothing
          }
        }
      });

      return found;
    },
    flyTo_site() {
      return this?.flyToSearch?.site;
    },
    flyTo_layer() {
      return this?.flyToSearch?.layer;
    },
    flyTo_Site_Marker_Number() {
      return this?.flyTo_site?.properties?.Site_Marker_Number;
    },
    flyTo_Point_Name() {
      return this?.flyTo_site?.properties?.Point_Name;
    }
  },
  methods: {
    clickEvent() {
      this.flyToPoint(this.flyTo_layer, this.flyTo_site);
    },
    flyToPoint(layer, site) {
      eventBus.$emit("flyToPoint", layer, site);
    }
  },
  async mounted() {}
};
</script>
