<template>
  <font class="engAndTrans">
    {{ value }}

    <font translate="no" class="engAndTrans englishVersion notranslate">
      - {{ value }}
    </font>
  </font>
</template>

<script>
export default {
  name: "engAndTrans",

  props: {
    value: String,
    currentCountryNotEnglish: Boolean
  }
};
</script>
<style>
.engAndTrans {
  background-attachment: red;
}
.englishVersion {
  visibility: hidden;
  display: none;
}
.currentCountryNotEnglish .englishVersion {
  visibility: visible;
  display: initial;
}
</style>
