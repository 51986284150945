<template>
  <maptivateInfoButton
    :Site_Marker_Number="Site_Marker_Number"
    :isPopup="isPopup"
    butType="audioPlay"
    v-if="audioUrl"
    :width="width"
    :color="appSettings.mediaButtonColor"
    :icon="siteAudio__audioPlayIcon_01"
    :title="playSite_AudioTitle01"
    :clickfunction="
      () => {
        setSound(audioUrl, Point_Name, Site_Marker_Number);
      }
    "
  />
</template>

<script>
import maptivateInfoButton from "../ui/maptivateInfoButton";

export default {
  name: "AudioPlayButton",
  components: {
    maptivateInfoButton
  },
  data: () => ({
    userAgent: "",
    isIpad: false,
    ready: false,
    siteAudio__audioPlayIcon_01: "",
    playTitle: "",
    interval: false,
    playSite_AudioTitle01: ""
  }),

  props: {
    Point_Name: String,
    Site_Marker_Number: String,

    getAudioDetails: Function,
    setSound: Function,

    sectionOrder: Number,
    sectionCount: Number,

    audioUrl: String,
    title: String,

    width: String,
    appSettings: Object,

    isPopup: Boolean
  },
  computed: {
    textWidth() {
      try {
        return `${parseFloat(this.width.replace("px", "")) - 30}px`;
      } catch (e) {
        //do nothing;
        return "";
      }
    }
  },

  methods: {
    updatePlayTitle(type, name, id, soundCloudTitle, hasMulti) {
      let tempAudio = this.getAudioDetails(name, id);

      let tempIcon = "false";
      let title = "";
      if (tempAudio && !tempAudio.isLoadedThisTrack) {
        title = "Play Audio";
        tempIcon = "mdi-play-outline";
        if (
          this.$browserDetect.isIOS ||
          this.$browserDetect.isChromeIOS ||
          this.$browserDetect.isSafari ||
          this.isIpad
        ) {
          title = "Load Audio";
          tempIcon = "mdi-volume-high";
        }
      } else if (!tempAudio.isPlaying && tempAudio.isLoadedThisTrack) {
        title = "Play Audio";
        tempIcon = "mdi-play-outline";
      } else if (tempAudio.isPlayingThisTrack) {
        title = "Pause Audio";
        tempIcon = "mdi-pause";
      } else {
        title = "Load Audio";
        tempIcon = "mdi-cassette";
      }

      this.playTitle = title;
      let playSite_AudioTitle01;

      if (hasMulti) {
        playSite_AudioTitle01 = title.replace(
          "Audio",
          soundCloudTitle ? soundCloudTitle : `Site Audio ${this.sectionOrder}`
        );
      } else {
        playSite_AudioTitle01 = title.replace(
          "Audio",
          soundCloudTitle ? soundCloudTitle : "Site Audio"
        );
      }

      //this.playSite_AudioTitle01 = this.playTitle;
      //this.siteAudio__audioPlayIcon_01 = tempIcon;

      this.playSite_AudioTitle01 = playSite_AudioTitle01;
      this.siteAudio__audioPlayIcon_01 = tempIcon;
    }
  },

  async mounted() {
    var userAgent = window.navigator.userAgent;
    this.userAgent = userAgent;
    if (
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/Macintosh/i)
    ) {
      this.isIpad = true;
    } else {
      // Anything else
    }

    let _this = this;

    let hasMulti = false;
    if (this.sectionCount > 1) {
      hasMulti = true;
    }

    _this.updatePlayTitle(
      "SiteAudio01",
      _this.Point_Name,
      _this.audioUrl,
      hasMulti
    );

    _this.interval = setInterval(function() {
      _this.updatePlayTitle(
        "SiteAudio01",
        _this.Point_Name,
        _this.audioUrl,
        _this.title,
        hasMulti
      );
    }, 150);
  },
  async destroyed() {
    clearInterval(this.interval);
  }
};
</script>
