//const train_line_new = require("./move/train_line_new.json");

//#9bd3a3
const walkA = require("./A/tracks.json");
const walkB = require("./B/tracks.json");

/*
SQL for work_Existing_Employment
SELECT (the_geom) FROM onemapsecondary.vic_o_plan_zone where (  qzone_code ~ ', C1Z.*,|, B1Z.*,|, B2Z.*,|, C2Z.*,|, B3Z.*,|, B4Z.*,|, B5Z.*,|, IN1Z.*,|, IN2Z.*,|, IN3Z.*,' )
and
   ST_Intersects("onemapsecondary".vic_o_plan_zone.the_geom ,ST_SetSRID(ST_MakePolygon(ST_GeomFromText('LINESTRING(144.2892837524414 -38.09998264736479,144.38026428222653 -38.10538590283809,144.42935943603516 -38.16263584058639,144.415283203125 -38.19448191395686,144.30747985839844 -38.18449777869456,144.283447265625 -38.146707585003405,144.2892837524414 -38.09998264736479)')), 4326))
*/

/*
let principal_bicycle_network_pbn = require("./play/principal_bicycle_network_pbn.json");

let styles = {};
principal_bicycle_network_pbn.features.forEach(row => {
  let { type, status } = row.properties;

  row.properties.styleValue = `${type.replace(" ", "")}_${status.replace(" ", "")}`;

  styles[row.properties.styleValue] = row.properties.styleValue;
});
*/
//console.log(styles);
const colors = {
  live: "#d97065",
  move: "#F3E64F",
  play: "#2bac96",
  //work: "#3d2969",
  work: "#9587cd",
  workUr: "#9587cd",
  workExisting: "#d8d7eb",
  workUrOpacity: 1,
  liveExistingOutlines: "#d97065", //"#ace6ea",
  liveExistingOutlinesOpacity: 0.5,
  liveExisting: "#9ccdd2", //"#ace6ea",
  liveProposed: "#d5afaa",
  liveExistingOpacity: 0.6,
  liveProposedOpacity: 0.9,
  genOpacity: 0.7,
  railCurrent: "#F3E64F",
  railNew: "#ff8400",
  railNull: "#fffde7",
  newRaodColor: "yellow"
};

let trainLineZoom = [
  "step",
  ["zoom"],
  0.3,
  8,
  0.6,
  11,
  1.5,
  12,
  2,
  14,
  4,
  18,
  6
];
let roadLineZoom = [
  "step",
  ["zoom"],
  0.3,
  8,
  0.6,
  11,
  1.5,
  12,
  3.5,
  14,
  7,
  18,
  8
];
/*
let currentResOutline = [
  "step",
  ["zoom"],
  0.3,
  8,
  0.4,
  11,
  1,
  12,
  2,
  14,
  3,
  18,
  3
];

let roadStyle = {
  "line-color": "#9ccdd2", //"#f3c33b", // Light Pink
  "line-width": roadLineZoom // Width of the line
};
*/
let roadStyleDashed = {
  "line-color": colors.move, //"#f3c33b", // Light Pink
  "line-width": roadLineZoom, // Width of the line
  "line-dasharray": [2, 2]
};

//console.log({ currentResOutline, roadStyle });
let trainAnimationZoom = 12;

var animationRunning = false;

export default new (class {
  async addLayers({ parentThis, map, appSettings }) {
    const fillSitUnder = appSettings?.layersUnder?.fill; //"tunnel-steps";
    const linesSitUnder = appSettings?.layersUnder?.lines; //"tunnel-steps";

    if (parentThis && map && appSettings) {
      //do nothing;
    }

    let layerSetup = {
      walkA: {
        maptivateLayerSlug: "fighting_extinction_species",
        title: "walkA",
        geoJson: walkA,
        section: "train",
        geomType: "line",
        antlineDash: false,
        subSection: "train_line_airport",
        style: {
          layout: {
            "line-join": "round",
            "line-cap": "round"
          },
          paint: {
            //"line-color": "#fbe25b",
            "line-color": colors.railNew,
            "line-width": trainLineZoom
          }
        }
      },
      walkB: {
        maptivateLayerSlug: "fighting_extinction_species",
        title: "walkB",
        geoJson: walkB,
        section: "train",
        geomType: "line",
        antlineDash: false,
        subSection: "train_line_airport",
        style: {
          layout: {
            "line-join": "round",
            "line-cap": "round"
          },
          paint: {
            //"line-color": "#fbe25b",
            "line-color": colors.railNew,
            "line-width": trainLineZoom
          }
        }
      }
    };

    const expandGeoJsonAndAddProps = ({
      title,
      clickLoadSiteByTitle,
      maptivateLayerSlug,
      geoJson,
      section,
      geomType,
      subSection
    }) => {
      geoJson.features.forEach(row => {
        row.properties.toolTipTitle = title;
        row.properties.section_slug = maptivateLayerSlug;
        row.properties.clickLoadSiteByTitle = clickLoadSiteByTitle;
        row.properties.hide = false;
        row.properties.isGeomLinePolyLayer = true;
        row.properties.subSection = subSection;
        row.properties.section = section;
        row.properties.geomType = geomType;
      });

      return geoJson.features;
    };

    let features = [];
    for (const [key, value] of Object.entries(layerSetup)) {
      if (key && value) {
        //
      }

      features = [...features, ...expandGeoJsonAndAddProps(value)];
    }

    parentThis.filterableDataSouceIds["mv_geomDataSource"] =
      "mv_geomDataSource";

    map.addSource("mv_geomDataSource", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [...features]
      }
    });
    /*
    const pastelColors = [
      "#FFB6C1", // Light Pink
      "#FFD700", // Gold
      "#FFA07A", // Light Salmon
      "#87CEEB", // Sky Blue
      "#98FB98", // Pale Green
      "#9370DB", // Medium Purple
      "#F08080", // Light Coral
      "#20B2AA", // Light Sea Green
      "#FF69B4", // Hot Pink
      "#ADD8E6", // Light Blue
      "#FF6347", // Tomato
      "#00CED1", // Dark Turquoise
      "#FFDAB9", // Peachpuff
      "#66CDAA", // Medium Aquamarine
      "#BA55D3" // Medium Orchid
    ];
*/

    let dashArrayLayers = [];

    let index = 0;
    for (const [key, value] of Object.entries(layerSetup)) {
      let { style } = value;
      let { layout, paint } = style;

      const getFilter = subSection => {
        return [
          "all",
          ["==", ["get", "subSection"], subSection],
          ["==", ["get", "hide"], false]
        ];
      };

      if (value.geomType == "line") {
        map.addLayer(
          {
            id: `move_layer_${key}`,
            type: "line",
            source: "mv_geomDataSource",
            layout: {
              "line-join": "round",
              "line-cap": "round",
              ...layout
            },
            paint: {
              "line-width": 8,
              ...paint
            },
            filter: getFilter(value.subSection),
            before: linesSitUnder // Specify the layer name you want to be below
          },
          linesSitUnder
        );

        let layerId = `move_layer_${key}_dash`;

        if (value.section === "train") {
          if (value.antlineDash === true) {
            dashArrayLayers.push(layerId);
          }
          map.addLayer(
            {
              id: layerId,
              type: "line",
              source: "mv_geomDataSource",
              paint: {
                "line-color": "gray",
                "line-width": trainLineZoom,
                "line-dasharray": [0, 4, 3]
              },
              filter: getFilter(value.subSection),
              before: linesSitUnder // Specify the layer name you want to be below
            },
            linesSitUnder
          );
        }

        if (value.section === "motorWay") {
          if (value.antlineDash === true) {
            dashArrayLayers.push(layerId);
          }
          map.addLayer(
            {
              id: layerId,
              type: "line",
              source: "mv_geomDataSource",
              paint: { ...roadStyleDashed },
              filter: getFilter(value.subSection),
              before: linesSitUnder // Specify the layer name you want to be below
            },
            linesSitUnder
          );
        }
      }

      if (value.geomType == "outline") {
        let layerId = `move_layer_${key}`;

        map.addLayer(
          {
            id: layerId,
            type: "line",
            source: "mv_geomDataSource",
            layout: {
              "line-join": "round",
              "line-cap": "round",
              ...layout
            },
            paint: {
              ...paint
            },
            filter: getFilter(value.subSection),
            before: linesSitUnder // Specify the layer name you want to be below
          },
          linesSitUnder
        );
      }

      if (value.geomType == "geom") {
        let layerId = `move_layer_${key}`;

        map.addLayer(
          {
            id: layerId,
            type: "fill",
            source: "mv_geomDataSource",
            layout: {
              ...layout
            },
            paint: {
              //"fill-color": pastelColors[index],
              "fill-opacity": 0.4,
              ...paint
            },
            filter: getFilter(value.subSection),
            before: fillSitUnder // Specify the layer name you want to be below
          },
          fillSitUnder
        );

        parentThis.tooltipPointLayerIds[layerId] = layerId;
        parentThis.mobileClickTooltipPointLayerIds[layerId] = layerId;

        if (value.clickLoadSiteByTitle) {
          parentThis.geomClickLoadPopup[layerId] = layerId;
        }
      }

      if (index) {
        //do nothing;
      }

      index++;
    }

    //start of ant line animations block;
    var dashArraySequence = [
      [0, 4, 3],
      [0.5, 4, 2.5],
      [1, 4, 2],
      [1.5, 4, 1.5],
      [2, 4, 1],
      [2.5, 4, 0.5],
      [3, 4, 0],
      [0, 0.5, 3, 3.5],
      [0, 1, 3, 3],
      [0, 1.5, 3, 2.5],
      [0, 2, 3, 2],
      [0, 2.5, 3, 1.5],
      [0, 3, 3, 1],
      [0, 3.5, 3, 0.5]
    ];

    var factor = 10;
    dashArraySequence.forEach(row => {
      row.forEach((value, index) => {
        row[index] = factor * value;
      });
    });

    let step = 0;

    function animateDashArray(timestamp) {
      // Update line-dasharray using the next value in dashArraySequence. The
      // divisor in the expression `timestamp / 50` controls the animation speed.
      const newStep = parseInt((timestamp / 50) % dashArraySequence.length);

      if (newStep !== step) {
        if (map.getZoom() > trainAnimationZoom) {
          //animation line;
          animationRunning = true;
          dashArrayLayers.forEach(layerId => {
            map.setPaintProperty(
              layerId,
              "line-dasharray",
              dashArraySequence[step]
            );
          });
        } else {
          //reset dashes when animation is stopped due to out of zoom;
          if (animationRunning == true) {
            dashArrayLayers.forEach(layerId => {
              map.setPaintProperty(layerId, "line-dasharray", [0, 4, 3]);
            });
          }
          animationRunning = false;
        }

        step = newStep;
      }

      // Request the next frame of the animation.
      requestAnimationFrame(animateDashArray);
    }

    if (animateDashArray) {
      //do noting!!
    }
    // start the animation
    //animateDashArray(0);

    //end of ant line animations block;
  }
})();
