<template>
  <div>
    <v-btn
      v-if="!href"
      depressed
      :color="color"
      :width="width"
      :class="`my-1 mx-2 white--text ${buttonclasses}`"
      @click="
        clickfunction();
        triggerEvent();
        exitFullScreen();
      "
    >
      <v-icon left>{{ icon }}</v-icon>
      <span class="buttitle" :style="{ 'max-width': textWidth }">
        {{ title }}
      </span>
    </v-btn>

    <v-btn
      v-if="href"
      depressed
      :color="color"
      :width="width"
      :class="`my-1 mx-2 white--text ${buttonclasses}`"
      :href="href"
      :target="target"
    >
      <v-icon left>{{ icon }}</v-icon>
      <span class="buttitle" :style="{ 'max-width': textWidth }">
        {{ title }}
      </span>
    </v-btn>
  </div>
</template>

<script>
import { eventBus } from "../../main";

export default {
  name: "maptivateInfoButton",
  components: {},

  props: {
    Site_Marker_Number: String,
    butType: String,
    isPopup: Boolean,

    width: String,
    color: String,
    icon: String,
    title: String,
    href: String,
    target: String,
    clickfunction: Function
  },
  methods: {
    exitFullScreen() {
      try {
        eventBus.$emit("nav_exitFullScreen");
      } catch (error) {
        //do nothing;
      }
    },
    triggerEvent() {
      let event_name = `${this.butType}_Click`;
      let event_params = `${this.Site_Marker_Number}_${this.title}_${
        this.isPopup ? "P" : "S"
      }`;

      window.mvAnalyticEvent("ui", event_name, event_params, 1);
    }
  },
  computed: {
    textWidth() {
      try {
        let textWidth = parseInt(this.width.replace("px", "")) - 30;
        return `${textWidth}px`;
      } catch (e) {
        return this.width;
      }
    },
    buttonclasses() {
      if (this.butType === "flyTo") {
        return "multiLine";
      } else {
        return "multiLine";
        //return "oneLineEclipsText";
      }
    }
  }
};
</script>

<style>
.v-btn.oneLineEclipsText span.buttitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-btn.multiLine span.buttitle {
  white-space: normal;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-btn.multiLine {
  height: auto !important;
  min-height: 36px;
}

.v-btn.multiLine span.v-btn__content {
  margin: 8px 7px !important;
}

.v-btn.multiLine span.v-btn__content i {
  margin-left: 6px;
  margin-right: 4px;
}
</style>
