<template>
  <maptivateInfoButton
    :Site_Marker_Number="Site_Marker_Number"
    :isPopup="isPopup"
    butType="playVideo"
    v-if="youTubeUrlTitle"
    :width="width"
    :color="appSettings.mediaButtonColor"
    icon="mdi-youtube-tv"
    :title="youTubeUrlTitle.title"
    :clickfunction="
      () => {
        openYoutube(youTubeUrlTitle.url, Point_Name);
      }
    "
  />
</template>

<script>
import maptivateInfoButton from "../ui/maptivateInfoButton";

export default {
  name: "VideoPlayButton",
  components: {
    maptivateInfoButton
  },
  computed: {
    youTubeUrlTitle() {
      let youTubeUrl = this.videoUrl;
      let title = this.title;

      if (!title) {
        title = "Play Video";

        if (this.sectionCount > 1) {
          if (this.sectionOrder == 1) {
            title = "Play 1st Video";
          } else if (this.sectionOrder == 2) {
            title = "Play 2nd Video";
          } else if (this.sectionOrder == 3) {
            title = "Play 3rd Video";
          } else if (this.sectionOrder == 4) {
            title = "Play 4th Video";
          } else if (this.sectionOrder == 5) {
            title = "Play 5th Video";
          }
        }
      }

      return { url: youTubeUrl, title };
    }
  },
  data: () => ({
    ready: false,
    audioPlayIcon: "false"
  }),
  props: {
    Point_Name: String,
    Site_Marker_Number: String,

    openYoutube: Function,

    sectionOrder: Number,
    sectionCount: Number,

    videoUrl: String,
    title: String,

    width: String,
    appSettings: Object,
    isPopup: Boolean
  },
  methods: {},
  async mounted() {}
};
</script>
<style>
.youTubeLinks .v-btn__content,
.soundcloudLinks .v-btn__content {
  width: 100%;
  white-space: normal;
}

button.v-btn.autoHeightButton.v-btn--has-bg.v-size--default {
  min-height: 36px;
  height: auto;
  padding: 5px 16px;
}
</style>
