var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"750"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2"},'v-icon',attrs,false),on),[_vm._v(" mdi-card-account-details ")])]}}]),model:{value:(_vm.auth0dialog),callback:function ($$v) {_vm.auth0dialog=$$v},expression:"auth0dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Item ")]),_c('div',{staticClass:"content"},[_c('v-divider'),[_c('v-card-title',{staticClass:"text-h5 blue lighten-2"},[_vm._v(" Point_Name: "+_vm._s(_vm.data.Point_Name)+" ")]),_c('br')],_c('v-divider'),[_c('v-card-title',{staticClass:"text-h5 blue lighten-2"},[_vm._v(" Site_Marker_Number: "+_vm._s(_vm.data.Site_Marker_Number)+" ")]),_c('br')],_c('v-divider'),[_c('v-card-title',{staticClass:"text-h5 blue lighten-2"},[_vm._v(" Content ")]),_c('hr'),_c('div',{domProps:{"innerHTML":_vm._s(
              _vm.data.content && _vm.data.content.rendered
                ? _vm.data.content.rendered
                : null
            )}}),_c('hr')],_c('v-divider'),_c('v-card-title',{staticClass:"text-h5 blue lighten-2"},[_vm._v(" Properties ")]),_c('vue-json-pretty',{attrs:{"data":_vm.cutDown}}),_c('v-divider'),(_vm.data.structuredData)?[_c('v-card-title',{staticClass:"text-h5 blue lighten-2"},[_vm._v(" StructuredData ")]),_c('vue-json-pretty',{attrs:{"data":_vm.data.structuredData}})]:_vm._e(),_c('v-divider'),(_vm.data.icon_obj)?[_c('v-card-title',{staticClass:"text-h5 blue lighten-2"},[_vm._v(" icon_obj ")]),_c('vue-json-pretty',{attrs:{"data":_vm.data.icon_obj}})]:_vm._e(),_c('v-divider'),[_c('v-card-title',{staticClass:"text-h5 blue lighten-2"},[_vm._v(" media ")]),_c('vue-json-pretty',{attrs:{"data":_vm.data.media}})],_c('v-divider')],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.auth0dialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }