<template>
  <div id="translatorListWrapper" v-if="!hide" style="">
    <div id="translatorList">
      <div>
        <div translate="no" class="notranslate">
          {{ currentCountryNotEnglish }}
          <hr />
          {{ translationLanguageCode }}
        </div>
        <hr />
        <table id="translatorListWords" v-if="currentCountryNotEnglish">
          <template v-for="(word, index) in wordsToTranslate">
            <tr
              v-bind:key="'item_' + index"
              v-if="
                !translatedList[translationLanguageCode] ||
                  !translatedList[translationLanguageCode][word]
              "
            >
              <td translate="no" class="notranslate index">
                {{ index }}
              </td>
              <td translate="no" class="notranslate word">
                {{ word }}
              </td>
              <td class="wordTranslated">{{ word }}</td>

              <td translate="no" class="notranslate word">
                {{ translationLanguageCode }}
              </td>

              <td translate="no" class="notranslate word">
                {{
                  translationLanguageCode === word.target &&
                    (!translatedList[translationLanguageCode] ||
                      !translatedList[translationLanguageCode][word])
                }}
              </td>
            </tr>
          </template>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
//import { eventBus } from "../main";

import { mapState } from "vuex";
let preList = require("./preList.json");

export default {
  components: {},
  name: "translatorList",
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      geoJson_makers: state => state.markers.markerList,
      geoJson_signage: state => state.markers.geoJson_signage,

      currentCountryNotEnglish: state =>
        state.translator.currentCountryNotEnglish,
      translationLanguageCode: state => state.translator.translationLanguageCode
    }),
    markerList() {
      return this.geoJson_makers.data.features;
    },
    signList() {
      return this.geoJson_signage.data.features;
    },
    lists() {
      return {
        places: this.markerList,
        signage: this.signList
      };
    },
    fields() {
      let defaultTooltipLabelField = null;
      defaultTooltipLabelField = this?.appSettings?.defaultTooltipLabelField;
      let places = this?.appSettings?.layerDefaults?.places?.labelField;
      let signage = this?.appSettings?.layerDefaults?.signage?.labelField;

      return {
        places: {
          label: places,
          tooltip: defaultTooltipLabelField ? defaultTooltipLabelField : places
        },
        signage: {
          label: signage,
          tooltip: defaultTooltipLabelField ? defaultTooltipLabelField : signage
        }
      };
    }
  },
  data() {
    return {
      runLoop: false,
      hide: false,
      words: [],
      wordsToTranslate: [],
      translatedList: { ...preList }
    };
  },
  methods: {
    createWordList() {
      let words = [];

      if (
        this.markerList.length &&
        this.signList.length &&
        !this.words.length
      ) {
        for (const [listKey, list] of Object.entries(this.lists)) {
          list.forEach(site => {
            try {
              let tempWord = site.properties[this.fields[listKey].label];
              if (!words.includes(tempWord)) {
                words.push(tempWord);
              }
            } catch (error) {
              //do nothing;
            }
            try {
              let tempWord = site.properties[this.fields[listKey].tooltip];
              if (!words.includes(tempWord)) {
                words.push(tempWord);
              }
            } catch (error) {
              //do nothing;
            }
          });
        }

        this.words = [...new Set(words)];
        this.wordsToTranslate = [...new Set(words)];
      }
    },

    doLookup() {
      let snapShotStart = JSON.stringify(
        JSON.parse(JSON.stringify(this.translatedList))
      );

      if (this.runLoop && this.currentCountryNotEnglish) {
        var table = document.getElementById("translatorListWords");
        for (var i = 0, row; (row = table.rows[i]); i++) {
          //iterate through rows
          //rows would be accessed using the "row" variable assigned in the for loop
          let word, translated, code;
          for (var j = 0, col; (col = row.cells[j]); j++) {
            //iterate through columns
            //columns would be accessed using the "col" variable assigned in the for loop
            if (j == 1 && col.innerText) {
              word = col.innerText;
            } else if (j == 2 && col.innerText) {
              if (col?.innerHTML?.includes("font")) {
                translated = col?.innerText;
              }
            } else if (j == 3 && col.innerText) {
              code = col.innerText;
            }
          }
          if (!this.translatedList[code]) {
            this.translatedList[code] = {};
          }
          if (translated && translated !== word && code) {
            this.translatedList[code][word] = translated;
          }
        }

        let snapShotEnd = JSON.stringify(
          JSON.parse(JSON.stringify(this.translatedList))
        );

        this.translatedList = JSON.parse(snapShotEnd);

        if (snapShotStart != snapShotEnd) {
          this.$store.dispatch("translationUpdateTranslatedList", {
            translatedList: this.translatedList
          });

          setTimeout(() => {
            this.hide = true;
            setTimeout(() => {
              this.hide = false;
            }, 100);
          }, 100);
        }
      }
    }
  },
  created() {
    setTimeout(() => {
      this.createWordList();

      this.$store.dispatch("translationUpdateTranslatedList", {
        translatedList: this.translatedList
      });

      setTimeout(() => {
        this.runLoop = true;
        setInterval(() => {
          this.doLookup();
        }, 777);
      }, 1000);
    }, 1000);
  },

  watch: {
    markerList() {
      this.createWordList();
    },
    signList() {
      this.createWordList();
    },
    translationLanguageCode() {
      this.runLoop = false;
      setInterval(() => {
        this.runLoop = true;
      }, 1300);
    }

    /*
    wordsToTranslate(newValue) {
      if (this.currentCountryNotEnglish && this.translationLanguageCode) {
        let value = JSON.stringify({
          currentCountryNotEnglish: this.currentCountryNotEnglish,
          translationLanguageCode: this.translationLanguageCode
        });

        setTimeout(() => {
          let value2 = JSON.stringify({
            currentCountryNotEnglish: this.currentCountryNotEnglish,
            translationLanguageCode: this.translationLanguageCode
          });

          this.doLookup(newValue, value, value2);
        }, 1500);
      }
    }*/
  }
};
</script>
<style scoped>
#translatorListWrapper {
  position: absolute;
  top: 100px;
  left: -400px;
  height: 400px;
  width: 400px;
  overflow: hidden;
}

#translatorList {
  width: 1000px;
  height: 400px;
  background: grey;
  overflow-y: scroll;
}
</style>
