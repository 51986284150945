// import Vue from 'vue'

const getIsDevEnviroment = () => {
  let isDev = false;
  let host = window.location.host;

  try {
    host = host.toLocaleLowerCase();

    if (host.includes("-dev") || host.includes("localhost")) {
      isDev = true;
    }
  } catch (error) {
    //do nothing;
  }

  return isDev;
};

const clearUrlPath = function() {
  var blankUrl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;
  window.history.pushState({ path: blankUrl }, "", blankUrl);
};

const updatePath = properties => {
  let newId = properties.Site_Marker_Number;
  //let oldId = this.$route;
  //if (newId && newId !== oldId) {
  if (newId) {
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?id=${newId}`;

    window.history.pushState({ path: newurl }, "", newurl);
  }
};

const getters = {};
const state = {
  isDevEnviroment: getIsDevEnviroment(),
  baseurllocation: "/",
  //sidebarOpenInfo: false,
  sidebarSite: false,
  //openPopupSite: false,
  focusSite: false,
  siteList: {}
  //sidebarFocusList: false,
  //closeMenu: false,
  //navTabState: 0
};
const mutations = {
  UPDATE_SITE_LIST(state, newValue) {
    state.siteList = newValue;
  },
  UPDATE_SIDEBAR_SITE(state, newValue) {
    state.sidebarSite = newValue;

    if (state.sidebarSite === false) {
      clearUrlPath();
    } else {
      try {
        updatePath(state.siteList[state.sidebarSite].properties);
      } catch (error) {
        //do nothing!!!
      }
    }
  },
  UPDATE_FOCUS_SITE(state, newValue) {
    state.focusSite = newValue;
  }
};
const actions = {
  navUpdateSiteList({ commit }, sites) {
    commit("UPDATE_SITE_LIST", sites);
  },

  navUpdateSidebarSite({ commit }, focusSite) {
    commit("UPDATE_SIDEBAR_SITE", focusSite);
  },
  navUpdateFocusSite({ commit }, focusSite) {
    commit("UPDATE_FOCUS_SITE", focusSite);
    commit("UPDATE_SIDEBAR_SITE", focusSite);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
