<template>
  <maptivateInfoButton
    :Site_Marker_Number="Site_Marker_Number"
    :isPopup="isPopup"
    butType="galleryOpen"
    :width="width"
    :color="appSettings.mediaButtonColor"
    icon="mdi-film"
    title="View Gallery"
    :clickfunction="
      () => {
        loadImageGallery(Site_Marker_Number);
      }
    "
  />
</template>

<script>
import { eventBus } from "../../main";
import maptivateInfoButton from "../ui/maptivateInfoButton";

export default {
  name: "LoadGalleryButton",
  components: {
    maptivateInfoButton
  },
  props: {
    appSettings: Object,
    media: Array,
    Point_Name: String,
    Site_Marker_Number: String,
    width: String,

    isPopup: Boolean
  },
  methods: {
    loadImageGallery(Site_Marker_Number) {
      eventBus.$emit("loadGallery", Site_Marker_Number);
    }
  },
  async mounted() {}
};
</script>
