const createBlankImage = function({ width = 1, height = 1 }) {
  // this creates a blank image while the icons load.
  //var width = 1;

  var bytesPerPixel = 4;
  var blankImage = new Uint8Array(height * width * bytesPerPixel);
  for (var x = 0; x < height; x++) {
    for (var y = 0; y < width; y++) {
      var offset = (y * width + x) * bytesPerPixel;
      blankImage[offset + 0] = 1; // red
      blankImage[offset + 1] = 1; // green
      blankImage[offset + 2] = 1; // blue
      blankImage[offset + 3] = 0; // alpha
    }
  }
  return blankImage;
};

export default new (class {
  async initFalseImageObj(map) {
    let tempBlankImage = createBlankImage({ width: 1, height: 1 });
    map.addImage("false", {
      width: 1,
      height: 1,
      data: tempBlankImage
    });
  }
})();
