// import Vue from 'vue'

const getters = {};
const state = {
  translatedList: {},
  currentCountryNotEnglish: false,
  translationLanguageCode: "en|en",
  translationLanguageTitle: "English"
};

const mutations = {
  UPDATE_LANGUAGE(state, newValue) {
    let {
      currentCountryNotEnglish,
      translationLanguageCode,
      translationLanguageTitle
    } = newValue;
    state.currentCountryNotEnglish = currentCountryNotEnglish;
    state.translationLanguageCode = translationLanguageCode;
    state.translationLanguageTitle = translationLanguageTitle;
  },
  UPDATE_TRANSLATED_LIST(state, value) {
    let { translatedList } = value;

    let oldValue = JSON.parse(JSON.stringify(state.translatedList));
    if (oldValue !== JSON.parse(JSON.stringify(translatedList))) {
      state.translatedList = JSON.parse(JSON.stringify(translatedList));
    }
  }
};
const actions = {
  translationUpdateLanguage({ commit }, values) {
    commit("UPDATE_LANGUAGE", values);
  },

  translationUpdateTranslatedList({ commit }, values) {
    commit("UPDATE_TRANSLATED_LIST", values);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
