const moment = require("moment");

function decodeEntities(encodedString) {
  var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
  var translate = {
    nbsp: " ",
    amp: "&",
    quot: '"',
    lt: "<",
    gt: ">"
  };
  return encodedString
    .replace(translate_re, function(match, entity) {
      return translate[entity];
    })
    .replace(/&#(\d+);/gi, function(match, numStr) {
      var num = parseInt(numStr, 10);
      return String.fromCharCode(num);
    });
}

const htmlDecode = function(input, key) {
  if (key == "M_TA_descriptionx") {
    //return input.replaceAll("\r\n", "<br>");
    return input.replaceAll("\r\n", "");
  } else {
    //const doc = new DOMParser().parseFromString(input, "text/html");
    //return doc.documentElement.textContent;
    return decodeEntities(input);
  }
};

const processAcfData = function(acfData) {
  //this function takes data from wordpress custom fields, using the prefix of the lug to idntify what type of field that data is.
  //prfix: {details}, using slug as prfix.
  //toAdd::

  const labelDisplaySuffixes = {
    "##H_INFO": "hideInSiteInfo", //hide site info
    "##H_POP": "hideInPopUp", //only for subtitles
    "##S_POP": "showInPopUp" //show in popup;
  };

  const custDataTypes = {
    M_SM_: { sectionKey: "socialmedia", type: "url" },
    M_SC_: { sectionKey: "soundcloud", type: "link" },
    M_L_: { sectionKey: "links", type: "url" }, //point links as list
    M_SPECIAL_LINK_: { sectionKey: "link", subSection: "special", type: "url" }, //single titled link where label is the title
    M_B_LINK_: { sectionKey: "buttonlink", type: "url" }, //single titled link where label is the title
    M_VL_: { sectionKey: "videolink", type: "url" },

    M_ST_: { sectionKey: "subtitle", type: "subtitle", decode: true },

    M_NO_: {
      sectionKey: "note",
      subSection: "text",
      type: "note",
      decode: true
    },
    M_TA_: {
      sectionKey: "note",
      subSection: "textarea",
      type: "note",
      decode: true
    },

    M_NU_: { sectionKey: "value", subSection: "number", type: "value" },
    M_C_S_: { sectionKey: "value", subSection: "choiceSingle", type: "value" },
    M_C_M_: { sectionKey: "value", subSection: "choiceMulti", type: "array" },
    M_C_YN_: {
      sectionKey: "value",
      subSection: "choiceTrueFalse",
      type: "value"
    },

    M_FLYOBJ_: { sectionKey: "flytopoint", type: "flyto" },
    M_LINKCLONEOBJ_: { sectionKey: "clonedata", type: "clone" },
    M_GO_PHO_SPHERE_: { sectionKey: "photosphere", type: "url" },

    M_DATE_: { sectionKey: "datetime", subSection: "date", type: "date" },
    M_DATETIME_: {
      sectionKey: "datetime",
      subSection: "datetime",
      type: "date"
    },
    M_TIME_: { sectionKey: "datetime", subSection: "time", type: "date" }
  };

  let structuredData = {};
  let order = 0;

  let sectionCounter = {};

  if (acfData.M_SPECIAL_LINK_uacLink) {
    acfData.M_B_LINK_uacLink = acfData.M_SPECIAL_LINK_uacLink;
    delete acfData.M_SPECIAL_LINK_uacLink;
  }

  if (acfData.M_SPECIAL_LINK_instituteLink) {
    acfData.M_B_LINK_instituteLink = acfData.M_SPECIAL_LINK_instituteLink;
    delete acfData.M_SPECIAL_LINK_instituteLink;
  }

  for (var key in acfData) {
    let acfObj = acfData[key];

    let { label, value } = acfObj;

    let displayShowHideKeys = {};
    if (label) {
      //this code looks at codes in the lables to decided if they should show or hide in popup/site info
      for (var prefix in labelDisplaySuffixes) {
        let labelKeyMod = labelDisplaySuffixes[prefix];
        if (label.includes(prefix)) {
          displayShowHideKeys[labelKeyMod] = true;
        }
      }

      try {
        //if acf label contains ""_LABEL_"" use the part after the word as actual label.
        try {
          if (label.includes("##")) {
            label = label.split("##")[0].trim();
          }
        } catch (error) {
          //do nothing;
        }
        if (label.includes("_LABEL_")) {
          label = label
            .split("_LABEL_")[1]
            .replace(/_/g, " ")
            .replace(/-/g, " ")
            .trim();
          if (!label) {
            label = false;
          }
        }
      } catch (error) {
        //label = acfObj.label;
      }

      for (var prfix in custDataTypes) {
        if (key.startsWith(prfix)) {
          let { sectionKey, subSection, type, decode } = custDataTypes[prfix];

          if (!structuredData[sectionKey]) {
            structuredData[sectionKey] = {};
          }

          sectionCounter[sectionKey] = sectionCounter[sectionKey]
            ? sectionCounter[sectionKey]
            : 0;
          sectionCounter[sectionKey]++;

          structuredData[sectionKey][key] = {
            label,
            sectionKey,
            type,
            order,
            key,
            sectionOrder: sectionCounter[sectionKey],
            ...displayShowHideKeys
          };

          if (subSection) {
            structuredData[sectionKey][key].subSection = subSection;
          }

          if (decode === true) {
            value = htmlDecode(value, key, label);
          }

          if (subSection === "choiceTrueFalse") {
            structuredData[sectionKey][key]["value"] =
              value === true ? "Yes" : "No";
          } else if (sectionKey === "value" && type === "array") {
            structuredData[sectionKey][key]["values"] = value;
            structuredData[sectionKey][key]["value"] = value.join(", ");
          } else if (sectionKey === "datetime") {
            //date or time or datetime
            structuredData[sectionKey][key]["value"] = value;
            let temp;
            if (subSection === "datetime") {
              temp = moment(value, "YYYY-MM-DD H:m:s");
              structuredData[sectionKey][key]["value"] = temp.format(
                "Do MMM YYYY h:m a"
              );
            }
            if (subSection === "date") {
              temp = moment(value, "YYYYMMDD");
              structuredData[sectionKey][key]["value"] = temp.format(
                "Do MMM YYYY"
              );
            }
            if (subSection === "time") {
              temp = moment(value, "H:m:s");
              structuredData[sectionKey][key]["value"] = temp.format("h:m a");
            }
            if (temp) {
              structuredData[sectionKey][key]["date"] = temp.toDate();
            }
          } else if (typeof value === "object") {
            structuredData[sectionKey][key] = {
              ...structuredData[sectionKey][key],
              ...value
            };
          } else if (sectionKey == "socialmedia") {
            let socialBrands = [
              "instagram",
              "facebook",
              "twitter",
              "youtube",
              "tiktok",
              "linkedin",
              "pinterest"
            ];

            let title = "generic";
            //let search = "instagram";
            socialBrands.forEach(search => {
              if (
                value.trim() &&
                title == "generic" &&
                value
                  .trim()
                  .toLowerCase()
                  .includes(search)
              ) {
                title = search;
              }
            });

            if (value.trim() && value.toLowerCase().includes("http")) {
              structuredData[sectionKey][key]["title"] = title;
              structuredData[sectionKey][key]["url"] = value.trim();
            } else {
              delete structuredData[sectionKey][key];
            }
          } else {
            structuredData[sectionKey][key]["value"] = value;
          }

          order++;
        }
      }
    }
  }

  for (var sectionKey in structuredData) {
    let sectionSet = structuredData[sectionKey];
    for (var fieldKey in sectionSet) {
      if (sectionCounter[sectionKey]) {
        structuredData[sectionKey][fieldKey].sectionCount =
          sectionCounter[sectionKey];
      }
    }
  }

  return structuredData;
};

const processAcfTaxonomy = function(acfData) {
  let options = {
    imageSettings: {},
    layerOptions: {
      topLevel: {},
      paint: {},
      layout: {}
    }
  };

  const validKeyPrfixes = ["MLO_", "MI_"];

  for (var key in acfData) {
    let issue = false;

    let isValid = validKeyPrfixes.filter(prefix => key.includes(prefix));
    let acfObj = acfData[key];

    let { label, value } = acfObj;

    if (isValid.length && (value || value === 0)) {
      //hasPrefix and a value;

      if (key.includes("#json_obj#")) {
        try {
          value = JSON.parse(value);
        } catch (error) {
          issue = true;
          console.error("issue with JSON.parse(value)", {
            error,
            key,
            label,
            value
          });
        }
      }

      if (key.includes("#num#")) {
        try {
          value = parseFloat(value);
        } catch (error) {
          issue = true;
          console.error("issue with parseFloat(value)", {
            error,
            key,
            label,
            value
          });
        }
      }
      if (key.includes("#bool#")) {
        try {
          if (value === true) {
            value === true;
          } else {
            value === false;
            issue = true;
          }
        } catch (error) {
          issue = true;
          console.error("issue with parseFloat(value)", {
            error,
            key,
            label,
            value
          });
        }
      }

      if (key.includes("#string#")) {
        try {
          value = String(String(value).trim());
          if (value) {
            value = String(value);
          } else {
            value === null;
            issue = true;
          }
        } catch (error) {
          issue = true;
          console.error("issue with parseFloat(value)", {
            error,
            key,
            label,
            value
          });
        }
      }

      if (issue === false && (value || value === 0)) {
        if (key.startsWith("MLO_") && key.includes("#core#_")) {
          let optionKey = key.split("#core#_")[1];
          options.layerOptions.topLevel[optionKey] = value;
        } else if (key.startsWith("MLO_") && key.includes("#layout#_")) {
          let optionKey = key.split("#layout#_")[1];
          options.layerOptions.layout[optionKey] = value;
        } else if (key.startsWith("MLO_") && key.includes("#paint#_")) {
          let optionKey = key.split("#paint#_")[1];
          options.layerOptions.paint[optionKey] = value;
        } else if (key === "MI_#num#_section_artwork_scale_factor") {
          options.imageSettings.section_artwork_scale_factor = value;
        }
      }
    }
  }

  return options;
};

module.exports.processAcfData = processAcfData;
module.exports.processAcfTaxonomy = processAcfTaxonomy;
