const loadImage = function(map, url, color) {
  const myPromise = new Promise((resolve, reject) => {
    map.loadImage(url, (err, image) => {
      if (err) {
        reject("error");
      }
      map.addImage(color, image);
      resolve("done");
    });
  });
  return myPromise;
};

export default new (class {
  async addLayers({ parentThis, map, appSettings }) {
    if (parentThis && map && appSettings) {
      //do nothing;
    }

    const images = [
      "blue.png",
      "green.png",
      "lime.png",
      "orange.png",
      "pink.png",
      "purple.png",
      "red.png",
      "turquoise.png"
    ];

    for (let i = 0; i < images.length; i++) {
      let color = images[i].replace(".png", "");
      let url = `./assets/icons/${images[i]}`;

      await loadImage(map, url, color);
    }

    const layerIdName = "arrowPoints";
    let geoJsonPoints = require("./geom/arrowLocations.json");
    /*
    {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: { rotation: 0, color: "green" },
          geometry: {
            coordinates: [144.95254437703363, -37.78512123485729],
            type: "Point"
          }
        },
        {
          type: "Feature",
          properties: { rotation: 45, color: "green" },
          geometry: {
            coordinates: [144.95233708324514, -37.785074427067116],
            type: "Point"
          }
        },
        {
          type: "Feature",
          properties: { rotation: 180, color: "pink" },
          geometry: {
            coordinates: [144.95213662331878, -37.78463155189323],
            type: "Point"
          }
        },
        {
          type: "Feature",
          properties: { rotation: 145, color: "green" },
          geometry: {
            coordinates: [144.95226874463435, -37.7849268023038],
            type: "Point"
          }
        }
      ]
    };
*/

    let fillSitUnder = appSettings?.layersUnder?.arrows;

    map.addSource(layerIdName, {
      type: "geojson",
      data: geoJsonPoints
    });

    const scaleFactor = 0.5;
    //const scaleFactor2 = 0.5;
    map.addLayer(
      {
        id: layerIdName,
        type: "symbol",
        source: layerIdName,

        maxZoom: 22,
        minZoom: 16,
        filter: ["!has", "isNorthArrow"],
        paint: {},
        layout: {
          "icon-rotation-alignment": "map",
          "icon-anchor": "center",
          "text-allow-overlap": true,
          "text-ignore-placement": true,
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "icon-size": [
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            0,
            14,
            0.05 * scaleFactor,
            15.5,
            0.05 * scaleFactor,
            16.5,
            0.2 * scaleFactor,
            17.5,
            0.5 * scaleFactor,
            18.5,
            1 * scaleFactor,
            21,
            2 * scaleFactor
          ],
          "icon-rotate": ["get", "rotation"],
          "icon-image": ["get", "color"]
        }
      },
      fillSitUnder
    );

    /*
    map.addLayer(
      {
        id: layerIdName+"_northArrow",
        type: "symbol",
        source: layerIdName,

        maxZoom: 22,
        minZoom: 16,
        filter: ['has', 'isNorthArrow'],
        paint: {},
        layout: {
          "icon-rotation-alignment": "map",
          "icon-anchor": "center",
          "text-allow-overlap": true,
          "text-ignore-placement": true,
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "icon-size": [
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            0,
            14,
            0.025 ,
            15.5,
            0.075 ,
            16.5,
            0.15 ,
            17.5,
            0.3 ,
            19,
            0.75 ,
            21,
            1.5
          ],
          "icon-rotate": ["get", "rotation"],
          "icon-image": ["get", "color"]
        }
      },
      fillSitUnder
    );
*/
  }
})();
