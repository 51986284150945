var axios = require("axios").default;

if (!axios) {
  axios = require("axios");
}

const pako = require("pako");

const atob = window.atob;

const _decode_layer_config = function(data) {
  try {
    data = data.c;
    data = pako.ungzip(atob(data.replace("B6PGZ_", "")), {
      to: "string"
    });
    return JSON.parse(data);
  } catch (e) {
    //console.log({ e });
    //do nothing
    return false;
  }
};

const _getData = async function({ useS3, useS3Address }) {
  let output;
  if (useS3 && useS3Address) {
    try {
      let results = await axios.get(useS3Address);
      output = results.data;
    } catch (e) {
      //console.error("errorB", e);
    }
  }
  if (!output) {
    //console.log("not getting from s3");
    output = require("./cacheData.json");
  }
  //console.log({ output });
  if (output.c) {
    try {
      //console.log(pako.ungzip((output.c), { to: "string" }));
      output = _decode_layer_config(output);
    } catch (error) {
      //do nothing
      //console.log({ error });
    }
  }

  return output;
};

module.exports.getData = _getData;
